import React from 'react';
import {
    ReloadOutlined,
} from '@ant-design/icons';
import {
    // Carousel,
    // Form,
    // Input,
    // Tooltip,
    // Icon,
    // Cascader,
    // Select,
    // Row,
    // Col,
    // Checkbox,
    // Button,
    // AutoComplete,
    Alert,
    Spin,
    // Divider,
    Typography,
    // Card,
    // notification,
    Table,
    Pagination,
} from 'antd';
import GenStyle from '~/components/Style';


const FormatTimestampToReadable = (ts) => {
    const date_ob = new Date(ts * 1000);

    // year as 4 digits (YYYY)
    const year = date_ob.getFullYear();

    // month as 2 digits (MM)
    const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // date as 2 digits (DD)
    const date = ("0" + date_ob.getDate()).slice(-2);

    // hours as 2 digits (hh)
    const hours = ("0" + date_ob.getHours()).slice(-2);

    // minutes as 2 digits (mm)
    const minutes = ("0" + date_ob.getMinutes()).slice(-2);

    // seconds as 2 digits (ss)
    const seconds = ("0" + date_ob.getSeconds()).slice(-2);

    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
}


export default ({
    loading,
    error,
    codes,

    pageCurrent,
    pageTotal,
    pageSize,
    pageOnChange,
    pageOnShowSizeChange,
}) => {
    return <GenStyle.Content>
        <Typography.Title>
            Redeem Code
        </Typography.Title>

        <Spin spinning={loading}>
            {error && <Alert type="error" message={error} />}
            
            <Table
                dataSource={codes.map(each => ({key: each.code, ap: each.items.ap, coin: each.items.coin, gem: each.items.gem, insertedAtText: FormatTimestampToReadable(each.inserted_at), ...each}))} 
                columns={[
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: 'AP',
                        dataIndex: 'ap',
                        key: 'ap',
                    },
                    {
                        title: 'Coin',
                        dataIndex: 'coin',
                        key: 'coin',
                    },
                    {
                        title: 'Gem',
                        dataIndex: 'gem',
                        key: 'gem',
                    },
                    {
                        title: 'InsertedAt',
                        dataIndex: 'insertedAtText',
                        key: 'coin',
                    }
                ]} 
            />

            {/* <Pagination
                defaultPageSize={pageSize}

                current={pageCurrent}
                total={pageTotal}
                size={pageSize}
                onChange={pageOnChange}
                onShowSizeChange={pageOnShowSizeChange}
                showSizeChanger
                showQuickJumper
                showTotal={(total, range) => `${range[0]} - ${range[1]} / ${total}`}
            /> */}
        </Spin>
    </GenStyle.Content>;
};
