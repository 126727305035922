import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from 'react-router-dom';

// import { Router } from "react-router";
// import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

// import store, { SagaMiddleware } from '~/store';
// import App from '~/components/App';
import SimpleErrorBoundary from '~/components/SimpleErrorBoundary';

import Home from '~/pages/code_list';

// ReactDOM.render(
//     <SimpleErrorBoundary>
//         <Router>
//             {/* <Provider store={store}> */}
//             <HelmetProvider>
//                 <title>Groobo</title>
//             </HelmetProvider>
//             {/* </Provider> */}
//             <Home />
//         </Router>
//     </SimpleErrorBoundary>,
//     document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <SimpleErrorBoundary>
        <Router>
            {/* <Provider store={store}> */}
            <HelmetProvider>
                <Home />
            </HelmetProvider>
            {/* </Provider> */}
        </Router>
    </SimpleErrorBoundary>
);
